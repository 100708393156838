<script>
// import { GoogleMap, Marker } from "vue3-google-map";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {firebase} from "../../../services/firebase";

/**
 * Google-map component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Monitoring agent en services",
      markers: [],
      agentsPosition: [],
      makerpselected: null,
      agent: null,
      Marker: [],
      items: [
        {
          text: "Maps",
          href: "/",
        },
        {
          text: "Agents en services",
          active: true,
        },
      ],
      // markers: [
      //   {
      //     position: { lat: 11.0068, lng: 12.67650 },
      //     visible: true
      //   },
      //   {
      //     position: { lat: 1.324350, lng: 6.342354650 },
      //     visible: true
      //   },
      // ],
    };
  },
  methods: {
    initMap() {
      this.map = new google.maps.Map(this.$refs["map"], {
        zoom: 10,
        center: new google.maps.LatLng(6.247046, 1.311771),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      this.map.addListener("click", (e) => {

      });


      this.getAgentPositionFromFirestore();
    },
    async getAgentPositionFromFirestore() {
      const self = this;
      firebase.firestore()
          .collection("partages_position")
          .onSnapshot(snap => {
            const agentsPosition = [];
            const infoWindows = [];
            const iconCommon = {
              url: "/maps/gpscar.png",
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(0, 0),
            };

            const icons = {
              moto: {...iconCommon, url: "/maps/gpsmoto.png"},
              car: {...iconCommon, url: "/maps/gpscar.png"},
              pieds: {...iconCommon, url: "/maps/mapuserwalker.png"},
              helico: {...iconCommon, url: "/maps/gpscar.png"},
            };

            console.log("snap.docs.length")
            console.log(snap.docs.length)
            for (let i = 0; i < snap.docs.length; i++) {
              var driver = snap.docs[i].data();
              const finalIcon = icons[driver.type_service] || iconCommon;
              const nmaker =
                  new google.maps.Marker({
                    map: this.map,
                    title: driver.type_service + ", agent: " + driver.agent + ", code: " + driver.serialNumber,
                    icon: finalIcon
                  })
              nmaker.addListener("click", ({domEvent, latLng}) => {
                const {target} = domEvent;
                /*self.position(driver)*/
              });

              agentsPosition.push(nmaker);
              agentsPosition[i].setPosition(
                  new google.maps.LatLng(driver.latitude, driver.longitude)
              );
              console.log(driver)
              infoWindows.push(new google.maps.InfoWindow({
                disableAutoPan: true
              }));
              /* infoWindows[i].setContent(
                   `<div class="ui header">Agent A </div>`
               );
               infoWindows[i].open(this.map, agentsPosition[i]);*/

            }
          });
    },
  },
  mounted() {
    this.initMap();
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Position des agents</h4>

            <section style="height: 700px;" ref="map" class="map"></section>
            <!-- Map with markers -->
<!--            <Markers class="leaflet-map"/>-->
            <!-- <GoogleMap
              :center="{ lat: 11, lng: 12 }"
              :zoom="3"
              style="height: 600px"
            >
              <Marker
              v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                :icon="'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png'"
                @click="center = m.position"
              >
            </Marker>
              
            </GoogleMap> -->
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
